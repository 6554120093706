<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-modal
      id="modal-disbarment-approve-offer"
      ref="modalDisbarmentApproveOffer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Royement accepteren"
      :hide-footer="true"
      :no-close-on-backdrop="false"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refDisbarmentApproveFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handelDisbarmentApprovalRequest)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="disbarmentNote"
                rules="required"
              >
                <b-form-group>
                  <label>Beschrijving</label>
                  <b-form-textarea
                    id="disbarmentNote"
                    v-model="disbarmentApprovalObject.disbarmentNote"
                    rows="2"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="paid"
                rules="required"
              >
                <b-form-group>
                  <b-form-checkbox
                    id="paid"
                    v-model="disbarmentApprovalObject.paid"
                    :value="true"
                    :unchecked-value="null"
                    plain
                    :state="getValidationState(validationContext)"
                  >
                    De klant heeft alle facturen voldaan
                  </b-form-checkbox>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showDisbarmentApprovalSpinner"
                  small
                  class="mr-1"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <section id="offer">
      <b-row>
        <b-col cols="12">
          <b-card
            no-body
          >

            <div class="m-2">

              <!-- Table Top -->
              <b-row>

                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>Records</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-end"
                  cols="12"
                  md="6"
                />
              </b-row>

            </div>
            <b-table
              ref="refDisbarmentListTable"
              :items="fetchDisbarment"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                <b-link
                  :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                  class="font-weight-bold"
                  style="color:#ff9f43"
                >
                  #{{ data.item.id }}
                </b-link>
              </template>

              <!-- Column: Client -->
              <template #cell(policyHolderRelation)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      src=""
                      :text="avatarText(getFullName(data.item.policyHolderRelation))"
                      style="background:#ff9f43"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ getFullName(data.item.policyHolderRelation) }}
                  </span>
                  <small class="text-muted">{{ data.item.policyHolderRelation.email }}</small>
                </b-media>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(timestamp)="data">
                <span class="text-nowrap">
                  {{ formatDateTime(data.value) }}
                </span>
              </template>

              <!-- Column: Id -->
              <template #cell(product)="data">
                {{ data.item.product.name }}
              </template>

              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`disbarment-row-${data.item.id}-approval-icon`"
                    class="cursor-pointer mr-1"
                    icon="CheckCircleIcon"
                    size="16"
                    style="color:rgb(40,199,111);"
                    @click="openProlongationsInsuredAmountModal(data.item.id)"
                  />
                  <feather-icon
                    :id="`disbarment-row-${data.item.id}-disapprive-icon`"
                    class="cursor-pointer"
                    icon="XCircleIcon"
                    size="16"
                    style="color:rgb(234,84,85) !important;"
                    @click="confirmDisabarmentDispproveText(data.item.id)"
                  />
                </div>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                    dataMeta.of
                  }} records zichtbaar</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalDisbarments"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BLink,
  BOverlay,
  BFormInvalidFeedback,
  BFormGroup,
  BForm,
  BButton,
  BPagination,
  BSpinner,
  BMedia,
  BAvatar,
  BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useDisbarmentList from '@/views/pages/disbarment/useDisbarmentList'
import { formatDateTime } from '@core/utils/utils'
import disbarmentStoreModule from '@/views/pages/disbarment/disbarmentStoreModule'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { decimal, required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BLink,
    vSelect,
    BOverlay,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BButton,
    BPagination,
    BSpinner,
    BMedia,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disbarmentApprovalObject: {
        disbarmentNote: '',
        status: 92,
        id: null,
        paid: null,
      },
      showDisbarmentApprovalSpinner: false,
    }
  },
  methods: {
    fetchStatistics() {
      store
        .dispatch('app-menu-statistics/fetchMenuStatistics')
        .then(response => {
          this.$store.commit('app/UPDATE_MENU_STATISTICS', response.data)
        })
    },
    openProlongationsInsuredAmountModal(id) {
      this.disbarmentApprovalObject.id = id
      this.$refs.modalDisbarmentApproveOffer.show()
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    confirmDisabarmentDispproveText(orderID) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, royement afwijzen!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-disbarments/updateDisbarment', { id: orderID, status: 10 })
            .then(() => {
              this.fetchStatistics()
              this.refetchDisbarmentData()
              this.$swal({
                icon: 'success',
                title: 'Royement!',
                text: 'De royement is afgewezen.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Royement afwijzen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    handelDisbarmentApprovalRequest() {
      this.showDisbarmentApprovalSpinner = true
      store.dispatch('app-disbarments/updateDisbarment', this.disbarmentApprovalObject)
        .then(() => {
          this.fetchStatistics()
          this.showDisbarmentApprovalSpinner = false
          this.$refs.modalDisbarmentApproveOffer.hide()
          this.refetchDisbarmentData()
          this.$swal({
            icon: 'success',
            title: 'Royement!',
            text: 'De acceptatie van de royement is verwerkt.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.showDisbarmentApprovalSpinner = false
          this.$refs.modalDisbarmentApproveOffer.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Royement accepteren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.saveDataError}`,
            },
          })
        })
    },
  },
  setup() {
    const DISBARMENT_APP_STORE_MODULE_NAME = 'app-disbarments'

    // Register module
    if (!store.hasModule(DISBARMENT_APP_STORE_MODULE_NAME)) store.registerModule(DISBARMENT_APP_STORE_MODULE_NAME, disbarmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DISBARMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DISBARMENT_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    const {
      fetchDisbarment,
      tableColumns,
      perPage,
      currentPage,
      totalDisbarments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDisbarmentListTable,
      refetchDisbarmentData,
      showOverlay,
    } = useDisbarmentList()

    return {
      fetchDisbarment,
      tableColumns,
      perPage,
      currentPage,
      totalDisbarments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDisbarmentListTable,
      refetchDisbarmentData,
      showOverlay,
      refFormObserver,
      getValidationState,
      required,
      decimal,
      avatarText,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .categorie-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
